import queryString from 'querystring';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useHistory, useLocation } from 'react-router-dom';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';

import { Category } from '@ttstr/api';
import { CategoryNavLinks, Navigation, useAuth, useProductDetails } from '@ttstr/components';
import MenuPortal from '@ttstr/components/Navigation/MenuPortal';
import UserLoginModal from '@ttstr/components/Navigation/UserLoginModal';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { categories, categoriesReceived } = useProductDetails();
  const { loggedIn, showLogin, setShowLogin, redirectAfterLogin } = useAuth();

  const qs = React.useMemo(() => {
    return queryString.parse(location.search.substring(1));
  }, [location.search]);

  const [mainMenuRef, setMainMenuRef] = React.useState<HTMLDivElement>(); // Could be put into Context
  const [customerMenuRef, setCustomerMenuRef] = React.useState<HTMLLIElement>(); // Could be put into Context
  const [navbarRef, setNavbarRef] = React.useState<HTMLUListElement>(); // Could be put into Context

  const toggleUserLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const isRoot = React.useMemo(() => {
    const matches = matchPath(location.pathname, { path: '/', exact: true });
    return matches && matches.isExact;
  }, [location.pathname]);

  const selectedCategoryId = qs.categoryId;

  return (
    <header id="header" className="d-print-none">
      <Navigation
        logo={require('./assets/images/logo.png')}
        // cartIcon={
        //   <svg
        //     className="icon icon-cart"
        //     height="11.125"
        //     aria-hidden="true"
        //     focusable="false"
        //     role="presentation"
        //     viewBox="0 0 8.6912374 22.25"
        //     fill="none"
        //     version="1.1"
        //     id="svg11"
        //     width="8.6912374"
        //   >
        //     <defs id="defs15" />
        //     <path
        //       fill="currentColor"
        //       fillRule="evenodd"
        //       d="M 4.3459547,0 A 4.75,4.75 0 0 0 -0.40404529,4.75 V 5.31 H -3.5640453 l -0.77,11.6 a 5,5 0 0 0 4.99000001,5.34 H 8.0359551 a 5,5 0 0 0 4.9899999,-5.33 l -0.77,-11.6 H 9.0959551 V 4.75 A 4.75,4.75 0 0 0 4.3459547,0 Z M 8.0959551,5.31 V 4.75 a 3.7500002,3.7500002 0 1 0 -7.50000039,0 v 0.56 z m -7.50000039,1 H 8.0959551 v 0.56 a 3.7500002,3.7500002 0 1 1 -7.50000039,0 z m -1,0 v 0.56 a 4.7500002,4.7500002 0 1 0 9.50000039,0 V 6.31 h 2.2199999 l 0.71,10.67 A 4,4 0 0 1 8.0359551,21.25 H 0.65595471 A 4,4 0 0 1 -3.3440453,16.98 l 0.72,-10.67 z"
        //       id="path9"
        //     />
        //   </svg>
        // }
        mainMenuRef={setMainMenuRef}
        customerMenuRef={setCustomerMenuRef}
        navbarRef={setNavbarRef}
        showRegister={false}
        alwaysCloseMainMenu
      />

      <MenuPortal target={navbarRef}>
        <NavItem>
          <NavLink to="/tickets" exact replace={isRoot} className="nav-link">
            {/* {t(`NAVIGATION.ALL`)} */}
            Tickets
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/products" exact replace={isRoot} className="nav-link">
            {/* {t(`NAVIGATION.ALL`)} */}
            Alle Produkte
          </NavLink>
        </NavItem>
        <CategoryNavLinks />
        {/* {categoriesReceived &&
          Object.values(categories)
            .sort((a: Category, b: Category) => a.position - b.position)
            .map((c: Category) => (
              <NavItem key={c.id}>
                <NavLink
                  to={`/category/${c.id}#product-listing`}
                  replace={isRoot}
                  isActive={() => selectedCategoryId === String(c.id)}
                  className="nav-link"
                >
                  {c.title}
                </NavLink>
              </NavItem>
            ))} */}
      </MenuPortal>

      <MenuPortal target={mainMenuRef}>
        <Nav navbar>
          <NavItem>
            <NavLink to="/tickets" exact replace={isRoot} className="nav-link">
              {/* {t(`NAVIGATION.ALL`)} */}
              Tickets
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/products" exact replace={isRoot} className="nav-link">
              {/* {t(`NAVIGATION.ALL`)} */}
              Alle Produkte
            </NavLink>
          </NavItem>
          <CategoryNavLinks />
          {/* {categoriesReceived &&
            Object.values(categories)
              .sort((a: Category, b: Category) => a.position - b.position)
              .map((c: Category) => (
                <NavItem key={c.id}>
                  <NavLink
                    to={`/category/${c.id}#product-listing`}
                    replace={isRoot}
                    isActive={() => selectedCategoryId === String(c.id)}
                    className="nav-link"
                  >
                    {c.title}
                  </NavLink>
                </NavItem>
              ))} */}
        </Nav>
      </MenuPortal>

      <MenuPortal target={customerMenuRef}>
        {loggedIn ? (
          <UncontrolledDropdown>
            <DropdownToggle nav caret title={t(`NAVIGATION.ACCOUNT`)} className="text-info">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-account"
                fill="none"
                viewBox="0 0 18 19"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                  fill="currentColor"
                ></path>
              </svg> */}
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
              <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/customer/account" exact>
                {t(`CUSTOMER.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/orders" exact>
                {t(`ORDERS.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/landing-page" exact>
                {t(`CUSTOMER.LANDING_PAGE.TITLE`)}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={NavLink} to="/customer/logout">
                {t('CUSTOMER.LOGOUT')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavItem tag="div">
            <NavLink
              to="#"
              isActive={() => false}
              onClick={(e) => {
                e.preventDefault();
                toggleUserLoginModal();
              }}
              className="nav-link"
              title={t(`NAVIGATION.ACCOUNT`)}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-account"
                fill="none"
                viewBox="0 0 18 19"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                  fill="currentColor"
                ></path>
              </svg> */}
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
              <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </NavLink>
          </NavItem>
        )}
        <UserLoginModal
          show={showLogin}
          toggle={toggleUserLoginModal}
          onLoginSuccess={() =>
            redirectAfterLogin && history.push(typeof qs.success === 'string' ? qs.success : '/customer/landing-page')
          }
        >
          <div className="alert alert-default p-0">{t(`LOGIN.INTRO`)}</div>
        </UserLoginModal>
      </MenuPortal>
    </header>
  );
};

export default React.memo(Header);
